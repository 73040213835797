<template>
  <div id="editor" class="background">
    <v-snackbar
        class="mb-14"
        :timeout="3000"
        color="#000000"
        v-model="alert"
        dark
      >
        <div class="text-center" style="font-weight: bold; letter-spacing: 2px">
          {{ alertdata }}
        </div>
        <template v-slot:actions>
          <v-btn color="indigo" @click="alert = false"> Close </v-btn>
        </template>
      </v-snackbar>

    <v-container class="pt-0">
      <div class="stick-nav px-3">
        <v-row class="mt-5">
          <v-col cols="12" sm="8">
            <h1 left class="text-left">Editor</h1>
          </v-col>
          <v-col cols="12" sm="4" class="done">
            <PreviewPopup
              :chartText="currentChart.content"
              previewMode="cellphone"
              :rowHeader="rowHeader"
              :colHeader="colHeader"
              :chartData="chartData"
              :unitValue="unit.value"
              :currentChart="currentChart"
              :colorSettings="color_settings"
              :allow_metric_conversion="allow_metric_conversion"
              :size_icon_text="size_icon_text"
              :subtitle="subtitle"
              :footer="footer"
            />
            <PreviewPopup
              :chartText="currentChart.content"
              previewMode="laptop"
              :rowHeader="rowHeader"
              :colHeader="colHeader"
              :chartData="chartData"
              :unitValue="unit.value"
              :currentChart="currentChart"
              :colorSettings="color_settings"
              :allow_metric_conversion="allow_metric_conversion"
              :size_icon_text="size_icon_text"
              :subtitle="subtitle"
              :footer="footer"
            />
            <v-btn
              @click="goLink"
              class="ma-2"
              width="200px"
              height="45px"
              outlined
              v-bind:color="linked === true ? 'black' : '#ee7768'"
            >
              <v-icon class="mr-2">mdi-tag-outline</v-icon>

              <div class="font-weight-black">Link to Products</div>
            </v-btn>

            <v-btn
              @click="closeEditor"
              class="ma-2"
              width="140px"
              height="45px"
              depressed
              dark
              color="#000000"
            >
              <v-icon class="mr-2">mdi-close</v-icon>
              <div class="font-weight-black">Close</div>
            </v-btn>
            <v-btn
              @click="saveChart"
              class="ma-2"
              width="200px"
              height="45px"
              depressed
              dark
              color="#000000"
              :disabled="isSaving"
            >
              <v-icon class="mr-2">mdi-content-save</v-icon>
              <div class="font-weight-black">Save and Close</div>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <v-card elevation="5" outlined rounded="200" class="pa-5 my-10">
        <!--  <v-row justify="end">
          <v-icon large color="#F1B24A"> mdi-numeric-1-circle </v-icon>
        </v-row> -->
        <v-row>
          <v-col>
            <div class="text-overline mb-1" style="font-weight: bold">
              SELECT A NAME
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              hint="This name will not be visible to your customers"
              dense
              color="#F1B24A"
              placeholder="Select a name"
              :rules="rules"
              hide-details="auto"
              v-model="currentChart.title"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card>

      <!-- Edit Content With Two Buttons -->

      <!-- Editor -->
      <v-card
        outlined
        elevation="5"
        rounded="200"
        class="pa-5 my-5"
        v-if="editorType !== 'care'"
      >
        <!--   <v-row justify="end">
          <v-icon large color="#F1B24A"> mdi-numeric-2-circle </v-icon>
        </v-row> -->
        <v-row>
          <v-col>
            <div class="text-overline mb-1" style="font-weight: bold">
              CREATE SIZE TABLE
            </div>
          </v-col>

          <v-col class="alignRight">
            <PreviewPopup
              :chartText="currentChart.content"
              previewMode="cellphone"
              :rowHeader="rowHeader"
              :colHeader="colHeader"
              :chartData="chartData"
              :unitValue="unit.value"
              :currentChart="currentChart"
              :colorSettings="color_settings"
              :allow_metric_conversion="allow_metric_conversion"
              :size_icon_text="size_icon_text"
              :subtitle="subtitle"
              :footer="footer"
            />
            <PreviewPopup
              :chartText="currentChart.content"
              previewMode="laptop"
              :rowHeader="rowHeader"
              :colHeader="colHeader"
              :chartData="chartData"
              :unitValue="unit.value"
              :currentChart="currentChart"
              :colorSettings="color_settings"
              :allow_metric_conversion="allow_metric_conversion"
              :size_icon_text="size_icon_text"
              :subtitle="subtitle"
              :footer="footer"
            />
          </v-col>
        </v-row>
        <!--   <v-row>

          <v-col>
            
            <v-list-item-title
              class="text-wrap text-left mt-4 text-h8 font-weight-regular"
            >
              Click on 'Add' if you wish to add more rows or columns. Click on X to delete rows and columns. Don't forget to indicate if the numbers are in centimeters
              or inches.
            </v-list-item-title>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col cols="12">
            <size-chart-editor
              :rowItems="rowHeader"
              :colItems="colHeader"
              :measureData="chartData"
              :unitValue="unit"
              :sizeChartID="sizeChartID"
              :allow_metric_conversion="
                this.currentChart.allow_metric_conversion
              "
              :popup_width_setting="this.currentChart.popup_width_setting"
              :subtitle="subtitle"
              :footer="footer"
              @changeSizeChart="changeSizeChart"
              @deleteRow="deleteRow"
              @deleteColumn="deleteColumn"
              @toggleAllowMetricConversion="toggleAllowMetricConversion"
              @selectPopupWidth="selectPopupWidth"
              @setSubtitle="setSubtitle"
              @setFooter="setFooter"
            />
          </v-col>
        </v-row>
      </v-card>

      <v-card elevation="5" outlined class="pa-5 my-5">
        <v-row justify="end">
          <!--  <v-icon large color="#F1B24A">
            {{ `mdi-numeric-${editorType === "care" ? "2" : "3"}-circle` }}
          </v-icon> -->
        </v-row>
        <v-row>
          <v-col>
            <div class="text-overline mb-1" style="font-weight: bold">
              CREATE MEASUREMENT INSTRUCTIONS
            </div>
          </v-col>
          <v-col class="alignRight">
            <PreviewPopup
              :chartText="currentChart.content"
              previewMode="cellphone"
              :rowHeader="rowHeader"
              :colHeader="colHeader"
              :chartData="chartData"
              :unitValue="unit.value"
              :currentChart="currentChart"
              :colorSettings="color_settings"
              :allow_metric_conversion="allow_metric_conversion"
              :size_icon_text="size_icon_text"
              :subtitle="subtitle"
              :footer="footer"
            />
            <PreviewPopup
              :chartText="currentChart.content"
              previewMode="laptop"
              :rowHeader="rowHeader"
              :colHeader="colHeader"
              :chartData="chartData"
              :unitValue="unit.value"
              :currentChart="currentChart"
              :colorSettings="color_settings"
              :allow_metric_conversion="allow_metric_conversion"
              :size_icon_text="size_icon_text"
              :subtitle="subtitle"
              :footer="footer"
            />
          </v-col>
        </v-row>

        <v-row class="mt-5 mb-3 d-flex justify-end">
          <v-dialog v-model="dialog_upload" persistent max-width="800">
  <template v-slot:activator="{ on, attrs }">
    <v-btn class="ma-2" min-width="200px" color="#6D6D6D" outlined height="40px" v-bind="attrs" v-on="on">
      Upload your own image
      <v-icon light class="ml-2" medium>mdi-image-outline</v-icon>
    </v-btn>
  </template>
  <v-card>
    <v-card-title class="text-h5" style="display: flex; justify-content: space-between; align-items: center;">
      Upload your own image
      <v-btn icon @click="dialog_upload = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-10" style="max-height: 600px; overflow-y: auto;">
      Follow these simple steps:
      <p class="mt-5">1. Upload your image in your Shopify account</p>
      <p>2. Click on 'copy link'</p>
      <p>3. Enter the link that you have copied in the image box of SmartSize</p>
      <img style="max-width: 750px" src="/upload_images.png">
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn :href="this.fileUploadURL" target="_blank" flat dark>Upload your image</v-btn>
      <v-btn flat text @click="dialog_upload = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>



          <v-dialog v-model="dialog_password" persistent max-width="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                min-width="200px"
                color="#6D6D6D"
                outlined
                height="40px"
                v-bind="attrs"
                v-on="on"
              >
                Image library
                <v-icon light class="ml-2" medium>mdi-camera-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5"> Your password </v-card-title>
              <v-card-text
                >Your password to access the Image Library of SmartSize is:

                <p
                  class="my-5 mr-5 flex-box justify-center"
                  style="font-size: 18px; color: red; font-weight: bold"
                >
                  s m a r t s i z e
                </p>

                <p></p
              ></v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  href="https://smartsize.io/image-library/"
                  target="_blank"
                  flat
                  dark
                >
                  Discover images
                </v-btn>
                <v-btn flat text @click="dialog_password = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            class="ma-2"
            outlined
            color="#6D6D6D"
            min-width="200px"
            height="40px"
            href="https://smartsize.zohodesk.eu/portal/en/kb/measurement-instructions"
            target="_blank"
          >
            How to ... <v-icon class="ml-3" medium>mdi-open-in-new</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-col cols="12">
            <editor
              api-key="rgzewf8bpj47rk4q9ygpl0bcuyaabatbmp3jxz580wge3228"
              :init="initValues"
              v-model="currentChart.content"
            ></editor>
          </v-col>
        </v-row>
      </v-card>

      <!-- Inspiration Library -->

      <v-row class="mt-4 mb-1 py-0">
        <v-col cols="12" md="10" style="display: flex; align-items: center">
          <v-fade-transition>
            <v-row :class="[savingChanges ? 'showClass' : 'notShow']">
              <v-chip class="ma-2" color="#BBFFCE">
                <v-col cols="12" md="6" class="text-left">
                  <h4 class="font-weight-light" style="color: #000000">
                    Saving Changes...
                  </h4>
                </v-col>
              </v-chip>
            </v-row>
          </v-fade-transition>
        </v-col>
      </v-row>

      <v-snackbar
        class="mb-14"
        :timeout="3000"
        color="#000000"
        v-model="alert"
        dark
      >
        <div class="text-center" style="font-weight: bold; letter-spacing: 2px">
          {{ alertdata }}
        </div>
        <template v-slot:actions>
          <v-btn color="indigo" @click="alert = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import SizeChartEditor from "../components/SizeChartEditor/SizeChartEditor.vue";
import PreviewPopup from "../components/PreviewPopup/PreviewPopup.vue";

let jsontoken = "";

export default {
  name: "Editor",
  components: {
    editor: Editor,
    SizeChartEditor,
    PreviewPopup,
  },
  data() {
    return {
      alert: false,
      alertdata: "",
      type: "success",
      title: "",
      message: "",

      linked: false,
      dialog_upload: false,
      dialog_image: false,
      /*       dialog_new_image: false,
       */ dialog_password: false,
      name: "",
      output: "",
      initValues: {
        height: 800,
        // menubar: "edit table view insert format help",
        menubar: "",
        statusbar: false,
        visual: false,
        image_description: false,

        // visual_table_class: "my-custom-class",
        table_column_resizing: "resizetable",

        plugins: [
          "preview",
          "image",
          "hr",
          "link",
          "media",
          "table",
          "emoticons",
          "visualblocks",
          "advlist autolink lists link image charmap print preview ",
          // "searchreplace visualblocks code fullscreen",
          " code ",
          " media paste code help",
          "textcolor colorpicker",
          // "tinydrive",
        ],
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 13pt 14pt 16pt 18pt 24pt 36pt",

        content_style:
          ".mce-content-body {font-size:10pt;font-family:Verdana,sans-serif;}",

        images_file_types: "png,jpg,svg,webp",

        /*   toolbar:
          "fullscreen | preview | undo redo| table properties | image | hr |fontselect | fontsizeselect |forecolor | bold italic | \
          alignleft aligncenter alignright alignjustify oudent indent  numlist | emoticons | help | code ", */

        toolbar:
          /*  "insertfile | undo redo| table properties | image | forecolor | bold italic |  alignleft aligncenter alignright alignjustify oudent indent  numlist | emoticons | code ", */
          "  undo redo| fontsizeselect forecolor | bold italic |  alignleft aligncenter alignright alignjustify | outdent indent |  bullist numlist | image media link  emoticons | code | ",
        imagetools_toolbar:
          "rotateleft rotateright | flipv fliph | editimage imageoptions",

        tinydrive_token_provider: function (success, failure) {
          success({ token: jsontoken });
          //success({ token: this.$store.state.jwtToken});
          // failure('Could not create a jwt token')
        },

        //Google Drive Integration
        //tinydrive_google_drive_key: 'AIzaSyBVlx97rbmBAGyKvwEqbIMaCE9FzOJqhn0',
        //tinydrive_google_drive_client_id: '164907401870-8sj8pke027jvul3p2bq44or24a4cq4gt.apps.googleusercontent.com',

        //Dropbox Integration
        //tinydrive_dropbox_app_key: 'esb7gvnhnt3ty57',

        table_default_styles: {
          width: "1000px",
          margin: "auto",
          bordercollapse: "collapse",
        },
      },

      visual: false,

      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      currentChart: {},
      interval: null,
      savingChanges: false,
      rowHeader: [],
      colHeader: [],
      chartData: [],
      unit: {
        id: 0,
        value: "Inches",
      },
      editorType: this.$route.query.type,
      deletedRow: [],
      deletedColumn: [],
      isSaving: false,
      color_settings: {},
      allow_metric_conversion: true,
      sizeChartID: this.$route.query.id,
      size_icon_text: "",
      popup_width: "",
      subtitle: "",
      footer: "",
    };
  },
  methods: {
    trigger_alert(alertdata) {
      this.type = alertdata.status;
      this.alertdata = alertdata.msg;
      this.alert = true;
    },
    fetchColorSchema() {
      this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/color_settings", {
          params: { shopname: localStorage.getItem("shop") },
        })
        .then((response) => {
          this.color_settings = response.data;
        })
        .catch((error) => console.log(error));
    },
    goLink() {
      //console.log("before going");
      localStorage.setItem("chartdata", JSON.stringify(this.currentChart));

      this.$store.dispatch("updateSizechartRowHeader", this.rowHeader);
      this.$store.dispatch("updateSizechartColHeader", this.colHeader);
      this.$store.dispatch("updateSizechartData", this.chartData);
      this.$store.dispatch("updateSizechartUnit", this.unit);
      this.$store.dispatch("updateSizechartDeletedRow", this.deletedRow);
      this.$store.dispatch("updateSizechartDeletedColumn", this.deletedColumn);

      this.$router.push({ name: "LinkProducts" });
    },

    async saveChart() {
      this.isSaving = true;

      this.trigger_alert({ status: "success", msg: "SAVING CHART..." });

      //console.log("save chart");
      let duplicated = Array.from(
        new Set(this.rowHeader.map((item) => item.chart_item_name))
      );
      if (
        this.rowHeader.length > 0 &&
        this.rowHeader.length !== duplicated.length
      ) {
        console.log(1);
        //return;
      }
      duplicated = Array.from(new Set(this.colHeader.map((item) => item.size)));
      if (
        this.colHeader.length > 0 &&
        (this.colHeader.length !== duplicated.length ||
          this.colHeader.findIndex((item) => item.size === "") !== -1)
      ) {
        console.log(2);
        //return;
      }

      if (
        this.chartData.length > 0 &&
        this.chartData.findIndex(
          (item) => item.findIndex((ele) => ele.measure === "") !== -1
        ) !== -1
      ) {
        console.log(3);
        //return;
      }

      try {
        const savedChart = await this.$http.post(
          process.env.VUE_APP_SERVER_URL + "/template/upsert",
          {
            shop: localStorage.getItem("shop"),
            chart_data: this.currentChart,
          }
        );
        const unitList = this.$store.getters.getChartUnits;
        const returnData =
          this.editorType === "care"
            ? { data: true }
            : await this.$http.post(
                process.env.VUE_APP_SERVER_URL + "/template/savesizechart",
                {
                  id: this.currentChart.id,
                  chart_id: savedChart.data.id,
                  chart_data: this.chartData,
                  row_data: this.rowHeader,
                  col_data: this.colHeader,
                  unit: {
                    id: this.unit.id,
                    value: unitList.find(
                      (item) => item.measure === this.unit.value
                    ).id,
                  },
                  deleted_row: this.deletedRow,
                  deleted_column: this.deletedColumn,
                }
              );
        this.isSaving = false;

        if (returnData.data === true) {
          this.$store.dispatch("upsertSizeChart", savedChart.data);

          this.$store.dispatch("resetInfiniteLoading");

          this.$router.push("/");
        }
      } catch (error) {
        this.isSaving = false;
        console.log(error);
        this.$store.dispatch("fetchSizeCharts");
        this.$store.dispatch("resetInfiniteLoading");
        this.$router.push("/");
      }
    },

    closeEditor() {
      this.$router.push("/");
    },

    async fetchchart(chartid) {
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/template/chart", {
          params: { chart_id: chartid, shop: localStorage.getItem("shop") },
        })
        .then((response) => {
          if (response.status === '500') {
            this.alertdata = "ERROR LOADING CHART. PLEASE TRY AGAIN OR CONTACT SUPPORT";
            this.alert = true;
            return null;
          };
          this.chart = response.data;
        })
        .catch((error) => console.log(error));
      if (this.chart.status != "error" || this.chart.id != null) {
        if (this.chart.template_text != null) {
          this.chart.template_text = this.chart.template_text.replace(
            /\\n/g,
            ""
          );
        }
        this.currentChart = {
          chart_id: this.chart.template_chart_id,
          id: this.chart.id,
          title: this.chart.name,
          published: this.chart.published,
          content: this.chart.chart_text,
          allow_metric_conversion: this.chart.allow_metric_conversion,
          popup_width_setting: this.chart.popup_width_setting,
          subtitle: this.chart.subtitle,
          footer: this.chart.footer,
        };
      }

      this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/template/sizechart", {
          params: { chart_id: chartid, shop: localStorage.getItem("shop") },
        })
        .then((response) => {

          if (response.status === '500') {
            this.alertdata = "ERROR LOADING CHART. PLEASE TRY AGAIN OR CONTACT SUPPORT";
            this.alert = true;
            return null;
          };

          const { data } = response;
          //console.log(data);
          this.rowHeader = [...data.chart_items];
          this.colHeader = [...data.chart_sizes];
          const unitList = this.$store.getters.getChartUnits;
          this.unit = {
            id: data.chart_measures.id,
            value: unitList.find(
              (item) => item.id === data.chart_measures.webg_chart_unitsId
            ).measure,
          };
          this.chartData = [...data.chart_measurements];
          this.color_settings = data.color_settings;
          this.allow_metric_conversion = data.allow_metric_conversion;
          this.size_icon_text = data.size_icon_text;
          this.popup_width = data.popup_width_setting;
          this.subtitle = data.subtitle;
          this.footer = data.footer;
        })
        .catch((error) => console.log(error));
    },
    publishChart() {
      this.currentChart.published = true;
      this.$store.dispatch("EditChart", this.currentChart);
      this.$router.push("/");
    },
    autoSave() {
      this.closeSaving();
    },
    closeSaving() {
      setTimeout(() => {
        this.$store.dispatch("EditChart", this.currentChart);
      }, 2000);
    },
    autoSaving() {
      this.interval = setInterval(
        this.myInterval,
        // Time For Repeating the cycle
        10000
      );
    },
    myInterval() {
      this.toggleSaving();
    },
    toggleSaving() {
      this.savingChanges = true;
      setTimeout(() => {
        this.savingChanges = false;

        // Time to show the Saving Changes ... ( Text)
      }, 3000);
    },
    toggleAllowMetricConversion(newVal) {
      // sorry for 2 sources of truth
      this.currentChart.allow_metric_conversion = newVal;
      this.allow_metric_conversion = newVal;
    },
    selectPopupWidth(newVal) {
      this.currentChart.popup_width_setting = newVal;
      this.popup_width = newVal;
    },
    setSubtitle(newVal) {
      this.currentChart.subtitle = newVal;
      this.subtitle = newVal;
    },
    setFooter(newVal) {
      this.currentChart.footer = newVal;
      this.footer = newVal;
    },
    changeSizeChart(rowHeader, colHeader, chartData, unit) {
      if (JSON.stringify(this.rowHeader) !== JSON.stringify(rowHeader)) {
        this.rowHeader = [...rowHeader];
      }
      if (JSON.stringify(this.colHeader) !== JSON.stringify(colHeader)) {
        this.colHeader = [...colHeader];
      }
      if (JSON.stringify(this.chartData) !== JSON.stringify(chartData)) {
        this.chartData = [...chartData];
      }
      if (JSON.stringify(this.unit) !== JSON.stringify(unit)) {
        this.unit = unit;
      }
    },
    deleteRow(index) {
      this.deletedRow.push(index);
    },
    deleteColumn(index) {
      this.deletedColumn.push(index);
    },
  },

  computed: {
    fileUploadURL() {
      let shop = localStorage.getItem("shop");
      let shortShopName = shop.substring(0, shop.indexOf("."));

      // Panda's URL:
      //return `https://${shop}/admin/settings/files`;

      // New Shopify URL:
      return `https://admin.shopify.com/store/${shortShopName}/content/files`;
    },
  },
  created() {
    if (this.$store.state.jwtToken == null) {
      const jwt = require("jsonwebtoken");
      const payload = {
        sub: localStorage.getItem("shop"), // Unique user id string
        name: localStorage.getItem("shop"), // Full name of user
        exp: Math.floor(Date.now() / 1000) + 60 * 10, // 10 minutes expiration
        ["https://claims.tiny.cloud/drive/root"]:
          "/" + localStorage.getItem("shop"),
      };
      const privateKey = `-----BEGIN PRIVATE KEY-----
MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCw2IZOavwk6dtj
a9n6heRpfmlH/DJawBTRdmuOCYsMIdPdJkUhTz2Faomtci3DqdK0CTCzhXwCeztC
lDoxswUfpAfTyapwpaFsnFSxqt0MTxyVai72RKMEVBtPCzLT0B8JPSIW4ne4MQF/
zVDshk1QozjTeCDR+miDom2d50R5+R7NeKvXoHOdZ+Wk2c5Dn2A8PrJqTrnWBgxN
dbxljlbSYWibQqvKhXAMzbFZQvaFRl6YrZqTBLwOxqf6AjwBIC5wcuSyqx9oa83D
4eunDZpTFD6P89kzlTAzCI76hTcQA0HUC0eMtFcANBh4gLeXeqCfGq0ksEV5RmgA
27sdTZE7AgMBAAECggEALH3NDF2OIhtTvqebp5OX65KKGjow0PQSSMBqPsnX2a2J
B9JhAghVigNLr/9R1DAden31arTihh1Kw21XVrmbSGKUNYw+/SgufIy4IhrCkh41
KsIUHpEWEji2xiQjHXb21/QxZ2CCjiyGHAkjmt4PBYKLAWjvmORO9w8eVOOiLq05
+QYJk//oSOkCwGEdw8JV+hLL5+Zd7HC3GF5Dr8gmAuYMEM/bzJIO5rIZu3PCqfEc
LujDAWi3s8R3wMlBMTDlpdtC8weQC32cm05a5o2SfxQz+JGn+kbycnXk84TeWlOn
/0CAUFJ/SrFILbzr7QIa28JbON25lXtmFH48mBrEQQKBgQD3BIW3xtlsis9hYEzT
wDYlUYywdTAvrLdPMBCqRhr9Uzyl8QD+ylooXO7NAbS22e6Rybr3gw3pcFMM7zY8
kL/QO0PEEXuO+Wtl5ZiFVgEFGBcBAIDcBeBHKDquABKqhq6e80yJAiqTaWNs6Pqx
osuh/xToXG6qyxeovbJ5+Or/fQKBgQC3RsZx/ALUwRfG0tZKzXCIG59oRBUiJxfI
RuwmZlDaYYSq9Vf2V0ZN4sgnlBXqvomDhfV2zzEvVdjoWCo+YFIaCPNeg3XR+Equ
x2LERL6vnYJZD6weKGHdGJYd8blx1+ziLj7QKppqE4O9FvWKFkBS/3XSxKOxfEvh
q64k2P+hFwKBgDYZLnPmP93utKZd4dWKRhF8P7SxpaD7UZYtL6kq+2Q5o5GwK4ih
pocqez44vmxBZGdXZ/QnJpfWesndZeJzzR58rEW3knjolSXW18Vz5XtJIFie1Jnb
S5gp7lgHOg9QudOj80IaUFrCfPzwNV9MCQ7sf5a4D6CmJANXsEvYQRc9AoGAKUeW
oMu7M4I+QbjxLS3yf6WBFPHotKWLdL+MpuV6poU6ZWsXIdPscNR4cnmnKhU6UdZV
QCpQkLHMRiC9ewkk1NpZPltrqRw8bftYoo49SL64QikGglBkInFMNnhgZWTV5Oe3
nBlMaEH29Rr7uNtkJ42EUzGZEPvG95A8i+4YPYcCgYAr84c15xmfQ4eY6PIx9XLI
m3ma6wjgVQOSx3Hioaama7MhE2Ex1iP4TFthwheAHGxbzWY61E/8seBe5VCHx66C
T8759PliKsNCd3l+xoHa67pk4eqU+ccLlksJ8FNhQt8S9KICyJIBAdJI2gnpz093
Xf0wbvwgt0qbeqVk4/Qgng==
-----END PRIVATE KEY-----`;

      const token = jwt.sign(payload, privateKey, { algorithm: "RS256" });

      //this.$store.state.jwtToken = JSON.stringify({token: token});
      this.$store.state.jwtToken = token;
      jsontoken = token;
    } else {
      jsontoken = this.$store.state.jwtToken;
    }
    this.fetchColorSchema();
  },

  mounted() {
    this.data = JSON.parse(localStorage.getItem("chartdata"));
    if (
      (this.data != null &&
        this.$route.query.source == "chart" &&
        this.$route.query.id == this.data?.id) ||
      (this.$route.query.source == "template" &&
        this.$route.query.chart_id == this.data?.chart_id)
    ) {
      this.currentChart = JSON.parse(localStorage.getItem("chartdata"));
      localStorage.setItem("chartdata", null);

      this.rowHeader = this.$store.state.sizechartRowHeader;
      this.colHeader = this.$store.state.sizechartColHeader;
      this.chartData = this.$store.state.sizechartData;
      this.unit = this.$store.state.sizechartUnit;
      this.deletedRow = this.$store.state.sizechartDeletedRow;
      this.deletedColumn = this.$store.state.sizechartDeletedColumn;

    } else {
      switch (this.$route.query.source) {
        case "chart":
          this.fetchchart(this.$route.query.id);
          break;
        case "template":
          //this.charts = JSON.parse(localStorage.getItem("charts"));
          this.charts = this.$store.getters.getTemplates;
          this.chart = this.charts.filter((item) => {
            return item.id == this.$route.query.chart_id;
          })[0];

          if (this.chart.template_text != null) {
            this.chart.template_text = this.chart.template_text.replace(
              /\\n/g,
              ""
            );
          }

          // Load default values for Size Table:

          this.rowHeader = [
            { chart_item_name: "Bust" },
            { chart_item_name: "Waist" },
            { chart_item_name: "Hips" },
          ];

          console.log("switch: ", this.$route.query.chart_id);
          switch (this.$route.query.chart_id) {
            // Top Woman chatGPT
            case 1:
              this.rowHeader = [
                { chart_item_name: "XS" },
                { chart_item_name: "S" },
                { chart_item_name: "M" },
                { chart_item_name: "L" },
                { chart_item_name: "XL" },
              ];

              this.colHeader = [
                { size: "Bust" },
                { size: "Waist" },
                { size: "Hips" },
                { size: "UK**" },
                { size: "EU**" },
                { size: "Australia**" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "31.5 - 32.5" },
                  { id: 2, measure: "23.5 - 24.5" },
                  { id: 3, measure: "33.5 - 34.5" },
                  { id: 4, measure: "4 - 6" },
                  { id: 5, measure: "32 - 34" },
                  { id: 6, measure: "6 - 8" },
                ],
                [
                  { id: 1, measure: "33.5 - 34.5" },
                  { id: 2, measure: "25.5 - 26.5" },
                  { id: 3, measure: "35.5 - 36.5" },
                  { id: 4, measure: "8 - 10" },
                  { id: 5, measure: "36 - 38" },
                  { id: 6, measure: "10 - 12" },
                ],
                [
                  { id: 1, measure: "35.5 - 36.5" },
                  { id: 2, measure: "27.5 - 28.5" },
                  { id: 3, measure: "37.5 - 38.5" },
                  { id: 4, measure: "12 - 14" },
                  { id: 5, measure: "40 - 42" },
                  { id: 6, measure: "14 - 16" },
                ],
                [
                  { id: 1, measure: "38 - 39.5" },
                  { id: 2, measure: "30 - 31.5" },
                  { id: 3, measure: "40 - 41.5" },
                  { id: 4, measure: "16 - 18" },
                  { id: 5, measure: "44 - 46" },
                  { id: 6, measure: "18 - 20" },
                ],
                [
                  { id: 1, measure: "41 - 43" },
                  { id: 2, measure: "33 - 35" },
                  { id: 3, measure: "43 - 45" },
                  { id: 4, measure: "20 - 22" },
                  { id: 5, measure: "48 - 50" },
                  { id: 6, measure: "22 - 24" },
                ],
              ];

              break;

            // Bikini chatGPT
            case 5:
              this.rowHeader = [
                { chart_item_name: "XS" },
                { chart_item_name: "S" },
                { chart_item_name: "M" },
                { chart_item_name: "L" },
                { chart_item_name: "XL" },
              ];

              this.colHeader = [
                { size: "Numeric Size" },
                { size: "Bust" },
                { size: "Cup Size" },
                { size: "Waist" },
                { size: "Hips" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "0 - 2" },
                  { id: 2, measure: "30 - 32" },
                  { id: 3, measure: "A" },
                  { id: 4, measure: "23.5 - 24.5" },
                  { id: 5, measure: "33.5 - 34.5" },
                ],
                [
                  { id: 1, measure: "4 - 6" },
                  { id: 2, measure: "32 - 34" },
                  { id: 3, measure: "A - B" },
                  { id: 4, measure: "25.5 - 26.5" },
                  { id: 5, measure: "35.5 - 36.5" },
                ],
                [
                  { id: 1, measure: "8 - 10" },
                  { id: 2, measure: "34 - 36" },
                  { id: 3, measure: "B - C" },
                  { id: 4, measure: "27.5 - 28.5" },
                  { id: 5, measure: "37.5 - 38.5" },
                ],
                [
                  { id: 1, measure: "12 - 14" },
                  { id: 2, measure: "36 - 38" },
                  { id: 3, measure: "C - D" },
                  { id: 4, measure: "30 - 31.5" },
                  { id: 5, measure: "40 - 41.5" },
                ],
                [
                  { id: 1, measure: "16 - 18" },
                  { id: 2, measure: "38 - 40" },
                  { id: 3, measure: "D - DD" },
                  { id: 4, measure: "33 - 35" },
                  { id: 5, measure: "43 - 45" },
                ],
              ];

              break;

            //Bra Woman chat GPT
            case 136:
              this.rowHeader = [
                { chart_item_name: "28" },
                { chart_item_name: "30" },
                { chart_item_name: "32" },
                { chart_item_name: "34" },
                { chart_item_name: "36" },
                { chart_item_name: "38" },
                { chart_item_name: "40" },
                { chart_item_name: "42" },
              ];

              this.colHeader = [
                { size: "Underbust" },
                { size: "Cup A" },
                { size: "Cup B" },
                { size: "Cup C" },
                { size: "Cup D" },
                { size: "Cup E" },
                { size: "Cup F" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "28 - 29" },
                  { id: 2, measure: "29 - 30" },
                  { id: 3, measure: "30 - 31" },
                  { id: 4, measure: "31 - 32" },
                  { id: 5, measure: "32 - 33" },
                  { id: 6, measure: "33 - 34" },
                  { id: 7, measure: "34 - 35" },
                ],
                [
                  { id: 1, measure: "30 - 31" },
                  { id: 2, measure: "31 - 32" },
                  { id: 3, measure: "32 - 33" },
                  { id: 4, measure: "33 - 34" },
                  { id: 5, measure: "34 - 35" },
                  { id: 6, measure: "35 - 36" },
                  { id: 7, measure: "36 - 37" },
                ],
                [
                  { id: 1, measure: "32 - 33" },
                  { id: 2, measure: "33 - 34" },
                  { id: 3, measure: "34 - 35" },
                  { id: 4, measure: "35 - 36" },
                  { id: 5, measure: "36 - 37" },
                  { id: 6, measure: "37 - 38" },
                  { id: 7, measure: "38 - 39" },
                ],
                [
                  { id: 1, measure: "34 - 35" },
                  { id: 2, measure: "35 - 36" },
                  { id: 3, measure: "36 - 37" },
                  { id: 4, measure: "37 - 38" },
                  { id: 5, measure: "38 - 39" },
                  { id: 6, measure: "39 - 40" },
                  { id: 7, measure: "40 - 41" },
                ],
                [
                  { id: 1, measure: "36 - 37" },
                  { id: 2, measure: "37 - 38" },
                  { id: 3, measure: "38 - 39" },
                  { id: 4, measure: "39 - 40" },
                  { id: 5, measure: "40 - 41" },
                  { id: 6, measure: "41 - 42" },
                  { id: 7, measure: "42 - 43" },
                ],
                [
                  { id: 1, measure: "38 - 39" },
                  { id: 2, measure: "39 - 40" },
                  { id: 3, measure: "40 - 41" },
                  { id: 4, measure: "41 - 42" },
                  { id: 5, measure: "42 - 43" },
                  { id: 6, measure: "43 - 44" },
                  { id: 7, measure: "44 - 45" },
                ],
                [
                  { id: 1, measure: "40 - 41" },
                  { id: 2, measure: "41 - 42" },
                  { id: 3, measure: "42 - 43" },
                  { id: 4, measure: "43 - 44" },
                  { id: 5, measure: "44 - 45" },
                  { id: 6, measure: "45 - 46" },
                  { id: 7, measure: "46 - 47" },
                ],
                [
                  { id: 1, measure: "42 - 43" },
                  { id: 2, measure: "43 - 44" },
                  { id: 3, measure: "44 - 45" },
                  { id: 4, measure: "45 - 46" },
                  { id: 5, measure: "46 - 47" },
                  { id: 6, measure: "47 - 48" },
                  { id: 7, measure: "48 - 49" },
                ],
                [
                  { id: 1, measure: "44 - 45" },
                  { id: 2, measure: "45 - 46" },
                  { id: 3, measure: "46 - 47" },
                  { id: 4, measure: "47 - 48" },
                  { id: 5, measure: "48 - 49" },
                  { id: 6, measure: "49 - 50" },
                  { id: 7, measure: "50 - 51" },
                ],
              ];

              break;
            // Bottom Woman chatGPT

            case 2:
              this.rowHeader = [
                { chart_item_name: "XS" },
                { chart_item_name: "S" },
                { chart_item_name: "M" },
                { chart_item_name: "L" },
                { chart_item_name: "XL" },
              ];

              this.colHeader = [
                { size: "Waist" },
                { size: "Hips" },
                { size: "Inseam" },
                { size: "UK**" },
                { size: "EU**" },
                { size: "Australia**" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "23.5 - 24.5" },
                  { id: 2, measure: "33.5 - 34.5" },
                  { id: 3, measure: "29 - 31" },
                  { id: 4, measure: "4 - 6" },
                  { id: 5, measure: "32 - 34" },
                  { id: 6, measure: "6 - 8" },
                ],
                [
                  { id: 1, measure: "25.5 - 26.5" },
                  { id: 2, measure: "35.5 - 36.5" },
                  { id: 3, measure: "29 - 31" },
                  { id: 4, measure: "8 - 10" },
                  { id: 5, measure: "36 - 38" },
                  { id: 6, measure: "10 - 12" },
                ],
                [
                  { id: 1, measure: "27.5 - 28.5" },
                  { id: 2, measure: "37.5 - 38.5" },
                  { id: 3, measure: "29 - 31" },
                  { id: 4, measure: "12 - 14" },
                  { id: 5, measure: "40 - 42" },
                  { id: 6, measure: "14 - 16" },
                ],
                [
                  { id: 1, measure: "30 - 31.5" },
                  { id: 2, measure: "40 - 41.5" },
                  { id: 3, measure: "30 - 32" },
                  { id: 4, measure: "16 - 18" },
                  { id: 5, measure: "44 - 46" },
                  { id: 6, measure: "18 - 20" },
                ],
                [
                  { id: 1, measure: "33 - 35" },
                  { id: 2, measure: "43 - 45" },
                  { id: 3, measure: "30 - 32" },
                  { id: 4, measure: "20 - 22" },
                  { id: 5, measure: "48 - 50" },
                  { id: 6, measure: "22 - 24" },
                ],
              ];

              break;

            // Dress Woman GPT
            case 4:
              this.rowHeader = [
                { chart_item_name: "XS" },
                { chart_item_name: "S" },
                { chart_item_name: "M" },
                { chart_item_name: "L" },
                { chart_item_name: "XL" },
              ];

              this.colHeader = [
                { size: "Bust" },
                { size: "Waist" },
                { size: "Hips" },
                { size: "UK**" },
                { size: "EU**" },
                { size: "Australia**" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "31.5 - 32.5" },
                  { id: 2, measure: "23.5 - 24.5" },
                  { id: 3, measure: "33.5 - 34.5" },
                  { id: 4, measure: "4 - 6" },
                  { id: 5, measure: "32 - 34" },
                  { id: 6, measure: "6 - 8" },
                ],
                [
                  { id: 1, measure: "33.5 - 34.5" },
                  { id: 2, measure: "25.5 - 26.5" },
                  { id: 3, measure: "35.5 - 36.5" },
                  { id: 4, measure: "8 - 10" },
                  { id: 5, measure: "36 - 38" },
                  { id: 6, measure: "10 - 12" },
                ],
                [
                  { id: 1, measure: "35.5 - 36.5" },
                  { id: 2, measure: "27.5 - 28.5" },
                  { id: 3, measure: "37.5 - 38.5" },
                  { id: 4, measure: "12 - 14" },
                  { id: 5, measure: "40 - 42" },
                  { id: 6, measure: "14 - 16" },
                ],
                [
                  { id: 1, measure: "38 - 39.5" },
                  { id: 2, measure: "30 - 31.5" },
                  { id: 3, measure: "40 - 41.5" },
                  { id: 4, measure: "16 - 18" },
                  { id: 5, measure: "44 - 46" },
                  { id: 6, measure: "18 - 20" },
                ],
                [
                  { id: 1, measure: "41 - 43" },
                  { id: 2, measure: "33 - 35" },
                  { id: 3, measure: "43 - 45" },
                  { id: 4, measure: "20 - 22" },
                  { id: 5, measure: "48 - 50" },
                  { id: 6, measure: "22 - 24" },
                ],
              ];

              break;

            // Outwear Woman
            case 9:
              this.rowHeader = [
                { chart_item_name: "XXS" },
                { chart_item_name: "XS" },
                { chart_item_name: "S" },
                { chart_item_name: "M" },
                { chart_item_name: "L" },
                { chart_item_name: "XL" },
                { chart_item_name: "XXL" },
                { chart_item_name: "XXXL" },
                { chart_item_name: "4XL" },
                { chart_item_name: "5XL" },
                { chart_item_name: "6XL" },
                { chart_item_name: "7XL" },
                { chart_item_name: "8XL" },
                { chart_item_name: "9XL" },
                { chart_item_name: "10XL" },
                { chart_item_name: "11XL" },
              ];

              this.colHeader = [
                { size: "BUST" },
                { size: "WAIST" },
                { size: "LOW HIP" },
                { size: "NECKLINE" },
                { size: "ARM LENGHT" },
                { size: "INSIDE LEG LENGHT" },
                { size: "UK/IE SIZE **" },
                { size: "US SIZE **" },
                { size: "EU SIZE **" },
                { size: "FR SIZE **" },
                { size: "IT SIZE **" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "30" },
                  { id: 2, measure: "23.5" },
                  { id: 3, measure: "33" },
                  { id: 4, measure: "13.5" },
                  { id: 5, measure: "23.25" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "4" },
                  { id: 8, measure: "0" },
                  { id: 9, measure: "32" },
                  { id: 10, measure: "34" },
                  { id: 11, measure: "38" },
                ],
                [
                  { id: 1, measure: "31.5" },
                  { id: 2, measure: "25" },
                  { id: 3, measure: "34.5" },
                  { id: 4, measure: "14" },
                  { id: 5, measure: "23.5" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "6" },
                  { id: 8, measure: "2" },
                  { id: 9, measure: "34" },
                  { id: 10, measure: "36" },
                  { id: 11, measure: "40" },
                ],
                [
                  { id: 1, measure: "33" },
                  { id: 2, measure: "26.75" },
                  { id: 3, measure: "36.25" },
                  { id: 4, measure: "14" },
                  { id: 5, measure: "23.5" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "8" },
                  { id: 8, measure: "4" },
                  { id: 9, measure: "36" },
                  { id: 10, measure: "38" },
                  { id: 11, measure: "42" },
                ],
                [
                  { id: 1, measure: "34.5" },
                  { id: 2, measure: "28.5" },
                  { id: 3, measure: "37.75" },
                  { id: 4, measure: "14.5" },
                  { id: 5, measure: "23.5" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "10" },
                  { id: 8, measure: "6" },
                  { id: 9, measure: "38" },
                  { id: 10, measure: "40" },
                  { id: 11, measure: "44" },
                ],
                [
                  { id: 1, measure: "36.25" },
                  { id: 2, measure: "30" },
                  { id: 3, measure: "39" },
                  { id: 4, measure: "15" },
                  { id: 5, measure: "23.75" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "12" },
                  { id: 8, measure: "8" },
                  { id: 9, measure: "40" },
                  { id: 10, measure: "42" },
                  { id: 11, measure: "46" },
                ],
                [
                  { id: 1, measure: "37.75" },
                  { id: 2, measure: "31.5" },
                  { id: 3, measure: "40.25" },
                  { id: 4, measure: "15.25" },
                  { id: 5, measure: "23.75" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "14" },
                  { id: 8, measure: "10" },
                  { id: 9, measure: "42" },
                  { id: 10, measure: "44" },
                  { id: 11, measure: "48" },
                ],
                [
                  { id: 1, measure: "39.5" },
                  { id: 2, measure: "33.5" },
                  { id: 3, measure: "41.25" },
                  { id: 4, measure: "15.75" },
                  { id: 5, measure: "23.75" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "16" },
                  { id: 8, measure: "12" },
                  { id: 9, measure: "44" },
                  { id: 10, measure: "46" },
                  { id: 11, measure: "50" },
                ],
                [
                  { id: 1, measure: "41" },
                  { id: 2, measure: "35.5" },
                  { id: 3, measure: "42.5" },
                  { id: 4, measure: "16" },
                  { id: 5, measure: "24" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "18" },
                  { id: 8, measure: "14" },
                  { id: 9, measure: "46" },
                  { id: 10, measure: "48" },
                  { id: 11, measure: "52" },
                ],
                [
                  { id: 1, measure: "43.5" },
                  { id: 2, measure: "37.75" },
                  { id: 3, measure: "44.5" },
                  { id: 4, measure: "16.5" },
                  { id: 5, measure: "24" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "20" },
                  { id: 8, measure: "16" },
                  { id: 9, measure: "48" },
                  { id: 10, measure: "50" },
                  { id: 11, measure: "54" },
                ],
                [
                  { id: 1, measure: "45.5" },
                  { id: 2, measure: "40.25" },
                  { id: 3, measure: "46.5" },
                  { id: 4, measure: "17" },
                  { id: 5, measure: "24" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "22" },
                  { id: 8, measure: "18" },
                  { id: 9, measure: "50" },
                  { id: 10, measure: "52" },
                  { id: 11, measure: "56" },
                ],
                [
                  { id: 1, measure: "48" },
                  { id: 2, measure: "42.5" },
                  { id: 3, measure: "48.5" },
                  { id: 4, measure: "17.25" },
                  { id: 5, measure: "24" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "24" },
                  { id: 8, measure: "20" },
                  { id: 9, measure: "52" },
                  { id: 10, measure: "54" },
                  { id: 11, measure: "58" },
                ],
                [
                  { id: 1, measure: "50.5" },
                  { id: 2, measure: "44.75" },
                  { id: 3, measure: "50.5" },
                  { id: 4, measure: "17.75" },
                  { id: 5, measure: "24.25" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "26" },
                  { id: 8, measure: "22" },
                  { id: 9, measure: "54" },
                  { id: 10, measure: "56" },
                  { id: 11, measure: "60" },
                ],
                [
                  { id: 1, measure: "52.75" },
                  { id: 2, measure: "47.5" },
                  { id: 3, measure: "52.75" },
                  { id: 4, measure: "18" },
                  { id: 5, measure: "24.25" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "28" },
                  { id: 8, measure: "24" },
                  { id: 9, measure: "56" },
                  { id: 10, measure: "58" },
                  { id: 11, measure: "62" },
                ],
                [
                  { id: 1, measure: "55" },
                  { id: 2, measure: "50.5" },
                  { id: 3, measure: "55.5" },
                  { id: 4, measure: "18.5" },
                  { id: 5, measure: "24.5" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "30" },
                  { id: 8, measure: "26" },
                  { id: 9, measure: "58" },
                  { id: 10, measure: "60" },
                  { id: 11, measure: "64" },
                ],
                [
                  { id: 1, measure: "57.5" },
                  { id: 2, measure: "53.25" },
                  { id: 3, measure: "57.5" },
                  { id: 4, measure: "18.75" },
                  { id: 5, measure: "24.5" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "32" },
                  { id: 8, measure: "28" },
                  { id: 9, measure: "60" },
                  { id: 10, measure: "62" },
                  { id: 11, measure: "66" },
                ],
                [
                  { id: 1, measure: "59.75" },
                  { id: 2, measure: "55.75" },
                  { id: 3, measure: "59.75" },
                  { id: 4, measure: "19" },
                  { id: 5, measure: "24.5" },
                  { id: 6, measure: "30.75" },
                  { id: 7, measure: "34" },
                  { id: 8, measure: "30" },
                  { id: 9, measure: "62" },
                  { id: 10, measure: "64" },
                  { id: 11, measure: "68" },
                ],
              ];
              break;

            // Shoes Woman
            case 10:
              this.rowHeader = [
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
              ];

              this.colHeader = [
                { size: "Foot Length" },
                { size: "EUR Size**" },
                { size: "UK Size**" },
                { size: "US Size**" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "8.66 - 8.82" },
                  { id: 2, measure: "35" },
                  { id: 3, measure: "2.5" },
                  { id: 4, measure: "4.5" },
                ],
                [
                  { id: 1, measure: "8.86 - 9.06" },
                  { id: 2, measure: "36" },
                  { id: 3, measure: "3.5" },
                  { id: 4, measure: "5.5" },
                ],
                [
                  { id: 1, measure: "9.09 - 9.29" },
                  { id: 2, measure: "37" },
                  { id: 3, measure: "4" },
                  { id: 4, measure: "6" },
                ],
                [
                  { id: 1, measure: "9.33 - 9.49" },
                  { id: 2, measure: "38" },
                  { id: 3, measure: "5" },
                  { id: 4, measure: "7" },
                ],
                [
                  { id: 1, measure: "9.53 - 9.69" },
                  { id: 2, measure: "39" },
                  { id: 3, measure: "6" },
                  { id: 4, measure: "8" },
                ],
                [
                  { id: 1, measure: "9.72 - 9.88" },
                  { id: 2, measure: "40" },
                  { id: 3, measure: "6.5" },
                  { id: 4, measure: "8.5" },
                ],
                [
                  { id: 1, measure: "9.92 - 10.08" },
                  { id: 2, measure: "41" },
                  { id: 3, measure: "7" },
                  { id: 4, measure: "9" },
                ],
                [
                  { id: 1, measure: "10.12 - 10.28" },
                  { id: 2, measure: "42" },
                  { id: 3, measure: "7.5" },
                  { id: 4, measure: "9.5" },
                ],
                [
                  { id: 1, measure: "10.32 - 10.47" },
                  { id: 2, measure: "43" },
                  { id: 3, measure: "8" },
                  { id: 4, measure: "10" },
                ],
              ];

              break;

            // Shoes Man
            case 116:
              this.rowHeader = [
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
                { chart_item_name: "" },
              ];

              this.colHeader = [
                { size: "Foot Length" },
                { size: "US Size**" },
                { size: "EU Size**" },
                { size: "UK Size**" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "9.3" },
                  { id: 2, measure: "6" },
                  { id: 3, measure: "39" },
                  { id: 4, measure: "5.5" },
                ],
                [
                  { id: 1, measure: "9.5" },
                  { id: 2, measure: "6.5" },
                  { id: 3, measure: "39.5" },
                  { id: 4, measure: "6" },
                ],
                [
                  { id: 1, measure: "9.6" },
                  { id: 2, measure: "7" },
                  { id: 3, measure: "40" },
                  { id: 4, measure: "6.5" },
                ],
                [
                  { id: 1, measure: "9.8" },
                  { id: 2, measure: "7.5" },
                  { id: 3, measure: "40.5 - 41" },
                  { id: 4, measure: "7" },
                ],
                [
                  { id: 1, measure: "10" },
                  { id: 2, measure: "8" },
                  { id: 3, measure: "41" },
                  { id: 4, measure: "7.5" },
                ],
                [
                  { id: 1, measure: "10.2" },
                  { id: 2, measure: "8.5" },
                  { id: 3, measure: "41.5 - 42" },
                  { id: 4, measure: "8" },
                ],
                [
                  { id: 1, measure: "10.3" },
                  { id: 2, measure: "9" },
                  { id: 3, measure: "42" },
                  { id: 4, measure: "8.5" },
                ],
                [
                  { id: 1, measure: "10.5" },
                  { id: 2, measure: "9.5" },
                  { id: 3, measure: "42.5 - 43" },
                  { id: 4, measure: "9" },
                ],
                [
                  { id: 1, measure: "10.7" },
                  { id: 2, measure: "10" },
                  { id: 3, measure: "43" },
                  { id: 4, measure: "9.5" },
                ],
                [
                  { id: 1, measure: "10.9" },
                  { id: 2, measure: "10.5" },
                  { id: 3, measure: "43.5 - 44" },
                  { id: 4, measure: "10" },
                ],
                [
                  { id: 1, measure: "11" },
                  { id: 2, measure: "11" },
                  { id: 3, measure: "44" },
                  { id: 4, measure: "10.5" },
                ],
                [
                  { id: 1, measure: "11.2" },
                  { id: 2, measure: "11.5" },
                  { id: 3, measure: "44.5 - 45" },
                  { id: 4, measure: "11" },
                ],
                [
                  { id: 1, measure: "11.4" },
                  { id: 2, measure: "12" },
                  { id: 3, measure: "45" },
                  { id: 4, measure: "11.5" },
                ],
                [
                  { id: 1, measure: "11.8" },
                  { id: 2, measure: "13" },
                  { id: 3, measure: "46 - 47" },
                  { id: 4, measure: "12.5 - 13" },
                ],
              ];

              break;

            // Shirt Man
            case 11:
              this.rowHeader = [
                { chart_item_name: "XS" },
                { chart_item_name: "S" },
                { chart_item_name: "M" },
                { chart_item_name: "L" },
              ];

              this.colHeader = [
                { size: "Neck" },
                { size: "Chest" },
                { size: "Sleeve" },
                { size: "Waist" },
                { size: "UK Size**" },
                { size: "EU Size**" },
                { size: "Australia Size**" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "13 - 13.5" },
                  { id: 2, measure: "33 - 34" },
                  { id: 3, measure: "31 - 32" },
                  { id: 4, measure: "27 - 28" },
                  { id: 5, measure: "33 - 34" },
                  { id: 6, measure: "42 - 44" },
                  { id: 7, measure: "14 - 14.5" },
                ],
                [
                  { id: 1, measure: "14 - 14.5" },
                  { id: 2, measure: "35 - 37" },
                  { id: 3, measure: "32 - 33" },
                  { id: 4, measure: "29 - 31" },
                  { id: 5, measure: "35 - 37" },
                  { id: 6, measure: "44 - 46" },
                  { id: 7, measure: "14.5 - 15" },
                ],
                [
                  { id: 1, measure: "15 - 15.5" },
                  { id: 2, measure: "38 - 40" },
                  { id: 3, measure: "33 - 34" },
                  { id: 4, measure: "32 - 34" },
                  { id: 5, measure: "38 - 40" },
                  { id: 6, measure: "48 - 50" },
                  { id: 7, measure: "15.5 - 16" },
                ],
                [
                  { id: 1, measure: "16 - 16.5" },
                  { id: 2, measure: "42 - 44" },
                  { id: 3, measure: "34 - 35" },
                  { id: 4, measure: "36 - 38" },
                  { id: 5, measure: "42 - 44" },
                  { id: 6, measure: "52 - 54" },
                  { id: 7, measure: "16.5 - 17" },
                ],
                [
                  { id: 1, measure: "46 - 48" },
                  { id: 2, measure: "35 - 36" },
                  { id: 3, measure: "40 - 42" },
                  { id: 4, measure: "46 - 48" },
                  { id: 5, measure: "56 - 58" },
                  { id: 6, measure: "17.5 - 18" },
                ],
                [
                  { id: 1, measure: "50 - 52" },
                  { id: 2, measure: "36 - 37" },
                  { id: 3, measure: "44 - 46" },
                  { id: 4, measure: "50 - 52" },
                  { id: 5, measure: "60 - 62" },
                  { id: 6, measure: "18.5 - 19" },
                ],
                [
                  { id: 1, measure: "54 - 56" },
                  { id: 2, measure: "37 - 38" },
                  { id: 3, measure: "48 - 50" },
                  { id: 4, measure: "54 - 56" },
                  { id: 5, measure: "64 - 66" },
                  { id: 6, measure: "19.5 - 20" },
                ],
                [
                  { id: 1, measure: "58 - 60" },
                  { id: 2, measure: "38 - 39" },
                  { id: 3, measure: "52 - 54" },
                  { id: 4, measure: "58 - 60" },
                  { id: 5, measure: "68 - 70" },
                  { id: 6, measure: "20.5 - 21" },
                ],
              ];
              break;

            // Polo Man
            case 137:
              this.rowHeader = [
                { chart_item_name: "XS" },
                { chart_item_name: "S" },
                { chart_item_name: "M" },
                { chart_item_name: "L" },
                { chart_item_name: "XL" },
                { chart_item_name: "XXL" },
                { chart_item_name: "3XL" },
                { chart_item_name: "4XL" },
              ];

              this.colHeader = [
                { size: "Chest" },
                { size: "Waist" },
                { size: "Hip" },
                { size: "UK" },
                { size: "EUR" },
                { size: "AUS" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "32-34" },
                  { id: 2, measure: "26-28" },
                  { id: 3, measure: "32-34" },
                  { id: 4, measure: "34" },
                  { id: 5, measure: "44" },
                  { id: 6, measure: "34" },
                ],
                [
                  { id: 1, measure: "35-37" },
                  { id: 2, measure: "29-31" },
                  { id: 3, measure: "35-37" },
                  { id: 4, measure: "36" },
                  { id: 5, measure: "46" },
                  { id: 6, measure: "36" },
                ],
                [
                  { id: 1, measure: "38-40" },
                  { id: 2, measure: "32-34" },
                  { id: 3, measure: "38-40" },
                  { id: 4, measure: "38-40" },
                  { id: 5, measure: "48-50" },
                  { id: 6, measure: "38-40" },
                ],
                [
                  { id: 1, measure: "41-43" },
                  { id: 2, measure: "35-37" },
                  { id: 3, measure: "41-43" },
                  { id: 4, measure: "42-44" },
                  { id: 5, measure: "52-54" },
                  { id: 6, measure: "42-44" },
                ],
                [
                  { id: 1, measure: "44-46" },
                  { id: 2, measure: "38-40" },
                  { id: 3, measure: "44-46" },
                  { id: 4, measure: "46" },
                  { id: 5, measure: "56" },
                  { id: 6, measure: "46" },
                ],
                [
                  { id: 1, measure: "47-49" },
                  { id: 2, measure: "41-43" },
                  { id: 3, measure: "47-49" },
                  { id: 4, measure: "48" },
                  { id: 5, measure: "58" },
                  { id: 6, measure: "48" },
                ],
                [
                  { id: 1, measure: "50-52" },
                  { id: 2, measure: "44-46" },
                  { id: 3, measure: "50-52" },
                  { id: 4, measure: "50" },
                  { id: 5, measure: "60" },
                  { id: 6, measure: "50" },
                ],
                [
                  { id: 1, measure: "53-55" },
                  { id: 2, measure: "47-49" },
                  { id: 3, measure: "53-55" },
                  { id: 4, measure: "52" },
                  { id: 5, measure: "62" },
                  { id: 6, measure: "52" },
                ],
              ];

              break;

            // Jacket Man
            case 14:
              this.rowHeader = [
                { chart_item_name: "34S" },
                { chart_item_name: "36S" },
                { chart_item_name: "38S" },
                { chart_item_name: "40S" },
                { chart_item_name: "42S" },
                { chart_item_name: "44S" },
                { chart_item_name: "46S" },
                { chart_item_name: "48S" },
                { chart_item_name: "50S" },
                { chart_item_name: "52S" },
                { chart_item_name: "54S" },
                { chart_item_name: "56S" },
              ];

              this.colHeader = [
                { size: "Chest" },
                { size: "Waist" },
                { size: "Sleeve" },
                { size: "UK Size**" },
                { size: "EU Size**" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "34" },
                  { id: 2, measure: "28" },
                  { id: 3, measure: "23.75" },
                  { id: 4, measure: "34S" },
                  { id: 5, measure: "44" },
                ],
                [
                  { id: 1, measure: "36" },
                  { id: 2, measure: "30" },
                  { id: 3, measure: "24" },
                  { id: 4, measure: "36S" },
                  { id: 5, measure: "46" },
                ],
                [
                  { id: 1, measure: "38" },
                  { id: 2, measure: "32" },
                  { id: 3, measure: "24.25" },
                  { id: 4, measure: "38S" },
                  { id: 5, measure: "48" },
                ],
                [
                  { id: 1, measure: "40" },
                  { id: 2, measure: "34" },
                  { id: 3, measure: "24.5" },
                  { id: 4, measure: "40S" },
                  { id: 5, measure: "50" },
                ],
                [
                  { id: 1, measure: "42" },
                  { id: 2, measure: "36" },
                  { id: 3, measure: "24.75" },
                  { id: 4, measure: "42S" },
                  { id: 5, measure: "52" },
                ],
                [
                  { id: 1, measure: "44" },
                  { id: 2, measure: "38" },
                  { id: 3, measure: "25" },
                  { id: 4, measure: "44S" },
                  { id: 5, measure: "54" },
                ],
                [
                  { id: 1, measure: "46" },
                  { id: 2, measure: "40" },
                  { id: 3, measure: "25.25" },
                  { id: 4, measure: "46S" },
                  { id: 5, measure: "56" },
                ],
                [
                  { id: 1, measure: "48" },
                  { id: 2, measure: "42" },
                  { id: 3, measure: "25.5" },
                  { id: 4, measure: "48S" },
                  { id: 5, measure: "58" },
                ],
                [
                  { id: 1, measure: "50" },
                  { id: 2, measure: "44" },
                  { id: 3, measure: "25.75" },
                  { id: 4, measure: "50S" },
                  { id: 5, measure: "60" },
                ],
                [
                  { id: 1, measure: "52" },
                  { id: 2, measure: "46" },
                  { id: 3, measure: "26" },
                  { id: 4, measure: "52S" },
                  { id: 5, measure: "62" },
                ],
                [
                  { id: 1, measure: "54" },
                  { id: 2, measure: "48" },
                  { id: 3, measure: "26.25" },
                  { id: 4, measure: "54S" },
                  { id: 5, measure: "64" },
                ],
                [
                  { id: 1, measure: "56" },
                  { id: 2, measure: "50" },
                  { id: 3, measure: "26.5" },
                  { id: 4, measure: "56S" },
                  { id: 5, measure: "66" },
                ],
              ];
              break;

            //Outwear man
            case 15:
              this.rowHeader = [
                { chart_item_name: "42" },
                { chart_item_name: "44" },
                { chart_item_name: "46" },
                { chart_item_name: "48" },
                { chart_item_name: "50" },
                { chart_item_name: "52" },
                { chart_item_name: "54" },
                { chart_item_name: "56" },
                { chart_item_name: "58" },
                { chart_item_name: "60" },
                { chart_item_name: "62" },
              ];

              this.colHeader = [
                { size: "HEIGHT" },
                { size: "CHEST" },
                { size: "WAIST" },
                { size: "NECKLINE" },
                { size: "ARMLENGTH" },
                { size: "UK SIZE **" },
                { size: "ALPHA **" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "69.5" },
                  { id: 2, measure: "33" },
                  { id: 3, measure: "28.25" },
                  { id: 4, measure: "13.75" },
                  { id: 5, measure: "24.25" },
                  { id: 6, measure: "32R" },
                  { id: 7, measure: "XS" },
                ],
                [
                  { id: 1, measure: "70" },
                  { id: 2, measure: "34.5" },
                  { id: 3, measure: "30" },
                  { id: 4, measure: "14" },
                  { id: 5, measure: "24.5" },
                  { id: 6, measure: "34R" },
                  { id: 7, measure: "S" },
                ],
                [
                  { id: 1, measure: "70.5" },
                  { id: 2, measure: "36.25" },
                  { id: 3, measure: "31.5" },
                  { id: 4, measure: "15.5" },
                  { id: 5, measure: "24.75" },
                  { id: 6, measure: "36R" },
                  { id: 7, measure: "S - M" },
                ],
                [
                  { id: 1, measure: "80" },
                  { id: 2, measure: "37.75" },
                  { id: 3, measure: "33" },
                  { id: 4, measure: "15" },
                  { id: 5, measure: "25" },
                  { id: 6, measure: "38R" },
                  { id: 7, measure: "M" },
                ],
                [
                  { id: 1, measure: "71" },
                  { id: 2, measure: "39.5" },
                  { id: 3, measure: "34.5" },
                  { id: 4, measure: "15.25" },
                  { id: 5, measure: "25" },
                  { id: 6, measure: "40R" },
                  { id: 7, measure: "M - L" },
                ],
                [
                  { id: 1, measure: "71.5" },
                  { id: 2, measure: "41" },
                  { id: 3, measure: "36.25" },
                  { id: 4, measure: "15.75" },
                  { id: 5, measure: "25.25" },
                  { id: 6, measure: "42R" },
                  { id: 7, measure: "L" },
                ],
                [
                  { id: 1, measure: "72" },
                  { id: 2, measure: "42.5" },
                  { id: 3, measure: "37.75" },
                  { id: 4, measure: "16" },
                  { id: 5, measure: "25.5" },
                  { id: 6, measure: "44R" },
                  { id: 7, measure: "L - XL" },
                ],
                [
                  { id: 1, measure: "72.5" },
                  { id: 2, measure: "44" },
                  { id: 3, measure: "39.5" },
                  { id: 4, measure: "16.5" },
                  { id: 5, measure: "25.75" },
                  { id: 6, measure: "46R" },
                  { id: 7, measure: "XL" },
                ],
                [
                  { id: 1, measure: "73" },
                  { id: 2, measure: "45.5" },
                  { id: 3, measure: "41" },
                  { id: 4, measure: "17" },
                  { id: 5, measure: "26" },
                  { id: 6, measure: "48R" },
                  { id: 7, measure: "XL - 2XL" },
                ],
                [
                  { id: 1, measure: "73.5" },
                  { id: 2, measure: "47.25" },
                  { id: 3, measure: "42.5" },
                  { id: 4, measure: "17.25" },
                  { id: 5, measure: "26" },
                  { id: 6, measure: "50R" },
                  { id: 7, measure: "2XL" },
                ],
                [
                  { id: 1, measure: "74" },
                  { id: 2, measure: "48.75" },
                  { id: 3, measure: "44" },
                  { id: 4, measure: "17.75" },
                  { id: 5, measure: "26.25" },
                  { id: 6, measure: "52R" },
                  { id: 7, measure: "2XL - 3 XL" },
                ],
              ];
              break;

            // Bottom Man
            case 12:
              this.rowHeader = [
                { chart_item_name: "XS" },
                { chart_item_name: "S" },
                { chart_item_name: "M" },
                { chart_item_name: "L" },
                { chart_item_name: "XL" },
                { chart_item_name: "2XL" },
              ];

              this.colHeader = [
                { size: "WAIST" },
                { size: "HIPS" },
                { size: "INSIDE LEG" },
                { size: "EU SIZE **" },
                { size: "UK SIZE **" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: "28.25" },
                  { id: 2, measure: "35.5" },
                  { id: 3, measure: "31.75" },
                  { id: 4, measure: "42" },
                  { id: 5, measure: "28R" },
                ],
                [
                  { id: 1, measure: "30 - 31.5" },
                  { id: 2, measure: "36.5 - 37.75" },
                  { id: 3, measure: "32" },
                  { id: 4, measure: "44 - 46" },
                  { id: 5, measure: "30R - 32R" },
                ],
                [
                  { id: 1, measure: "33 - 34.5" },
                  { id: 2, measure: "39 - 40" },
                  { id: 3, measure: "32.25 - 32.5" },
                  { id: 4, measure: "48 - 50" },
                  { id: 5, measure: "33R - 34R" },
                ],
                [
                  { id: 1, measure: "36.25 - 37.75" },
                  { id: 2, measure: "41.25 - 42.5" },
                  { id: 3, measure: "33" },
                  { id: 4, measure: "52 - 54" },
                  { id: 5, measure: "36R - 38R" },
                ],
                [
                  { id: 1, measure: "39.5 - 41" },
                  { id: 2, measure: "43.75 - 44.75" },
                  { id: 3, measure: "33.25 - 33.5" },
                  { id: 4, measure: "56 - 58" },
                  { id: 5, measure: "40R - 42R" },
                ],
                [
                  { id: 1, measure: "42.5 - 44" },
                  { id: 2, measure: "46 - 47.25" },
                  { id: 3, measure: "33.75 - 34" },
                  { id: 4, measure: "60 - 62" },
                  { id: 5, measure: "44R - 46R" },
                ],
              ];
              break;

            // Default case
            case 0:
              this.rowHeader = [
                { chart_item_name: "Bottom Bust" },
                { chart_item_name: "Lean Waist" },
                { chart_item_name: "Lean Hips" },
              ];

              this.colHeader = [
                { size: "S Lean" },
                { size: "M Lean" },
                { size: "L Lean" },
                { size: "XL" },
              ];

              this.chartData = [
                [
                  { id: 1, measure: 1 },
                  { id: 2, measure: 2 },
                  { id: 3, measure: 3 },
                  { id: 600, measure: 600 },
                ],
                [
                  { id: 4, measure: 4 },
                  { id: 5, measure: 5 },
                  { id: 6, measure: 6 },
                  { id: 60, measure: 60 },
                ],
                [
                  { id: 7, measure: 7 },
                  { id: 8, measure: 8 },
                  { id: 9, measure: 9 },
                  { id: 600, measure: 600 },
                ],
              ];

              break;

            default:
              this.rowHeader = [
                { chart_item_name: "Bust" },
                { chart_item_name: "Waist" },
                { chart_item_name: "Hips" },
              ];
              this.colHeader = [{ size: "S" }, { size: "M" }, { size: "L" }];
          }

          this.currentChart = {
            chart_id: this.chart.id,
            id: "new",
            title: this.chart.name,
            published: true,
            content: this.chart.template_text,
            // allow_metric_conversion undefined occurs before chart is created. At the moment it should have default value true
            allow_metric_conversion:
              this.chart.allow_metric_conversion === undefined
                ? true
                : this.chart.allow_metric_conversion,
            size_icon_text: this.size_icon_text,
            popup_width_setting:
              this.chart.popup_width_setting === undefined
                ? "medium"
                : this.chart.popup_width_setting,
            subtitle:
              this.chart.subtitle === undefined ? "" : this.chart.subtitle,
            footer: this.chart.footer === undefined ? "" : this.chart.footer,
          };
      }
    }
    //this.autoSaving();

    const axios = require("axios");

    console.log(
      "About to call axios to get linkedchart, with id: " + this.$route.query.id
    );

    axios
      .get(process.env.VUE_APP_SERVER_URL + "/linkedchart", {
        params: {
          id: this.$route.query.id,
        },
      })
      .then(
        (response) => {
          if ((response.status = "200")) {
            this.linked = response.data;
          } else {
            console.log("GET /linkedchart response.status: " + response.status);
          }
        },
        (error) => {
          console.log("GET /linkedchart error: " + error);
        }
      );

    console.log("Created");
  },
  destroyed() {
    this.savingChanges = false;
    console.log("Destroy");
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
 */
.stick-nav {
  position: sticky;
  top: 0px;
  background: #f3f5f7;
  z-index: 200;
  padding-top: 12px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #502c2c;
  margin-top: 60px;
}
.v-input--is-label-active label,
.v-input--is-focused label {
  left: -14px !important;
}
.v-select__selection {
  width: 100%;
  justify-content: right;
}
.BorderTop {
  border-top: 1pt solid #000000 !important;
}

.BorderBottom {
  border-bottom: 4pt solid #cf1010 !important;
}

.BorderRight {
  border-right: 2px solid #000000 !important;
}

.BorderLeft {
  border-left: 2px solid #000000 !important;
}
.table_row_no_border {
  border: none !important;
}
.table_no_border {
  border: 0px !important;
}

iframe .table_no_border {
  border: 0px !important;
}
/* iframe .table_row_no_border{
  border: none !important;
} */
table:not([cellpadding]) td,
table:not([cellpadding]) th {
  padding: 0.4rem !important;
}
table[border]:not([border="0"]):not([style*="border-color"]) td,
table[border]:not([border="0"]):not([style*="border-color"]) th {
  border-color: #ccc;
}
table {
  border-color: grey;
}

@media (max-width: 1088px) {
  .container {
    max-width: 900px !important;
    margin: 10px auto;
    padding-left: 80px !important;
  }
}
.showClass {
  opacity: 1;
}
.notShow {
  opacity: 0;
}
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
.done {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.alignRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
