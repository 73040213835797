<template>
  <select
    :name="name"
    :id="name"
    class="size-cell-input"
    @change="handleChange($event)"
  >
    <option
      v-for="(item, index) in measurementItems"
      v-bind:key="index"
      :value="item.id"
      :selected="item.id === defaultValue"
    >
      {{ item.measure }}
    </option>
  </select>
</template>
<script>
export default {
  props: ["defaultValue", "name"],
  computed: {
    measurementItems() {
      return this.$store.getters.getChartItems;
    },
  },
  methods: {
    handleChange(event) {
      this.$emit("onChanged", event.target.value);
    },
  },
};
</script>
<style lang="scss" scope>
.size-cell-input {
  flex-grow: 1;
  width: 80%;
  height: 30px;
  margin-right: 10px;
  border-style: solid;
  text-align: center;
  &:focus-visible {
    outline: none;
  }
}
</style>