<template>
  <div class="sizechart-container">
    <!-- <v-row>
      <v-col>
        <v-btn rounded depressed min-width="200px" height="40px">
          <span class="mr-2" role="img" aria-label="light bulb">💡</span>
          ctrl + v = paste
        </v-btn>
      </v-col>
    </v-row> -->

 

    <!-- Header -->
    <v-text-field
      v-model="subtitle"
      label="Header (optional)"
      clearable
      color="#F1B24A"
      @change="(e) => setSubtitle(e)"
      v-if="size_popup_version"
    ></v-text-field>

           <!-- Help Buttons -->
        <v-row class="mt-1 flex-box justify-end">

          <v-dialog v-model="dialog_tips" persistent max-width="500" max-height="600" @click:outside="dialog_tips = false">
  <template v-slot:activator="{ on, attrs }">
    <v-btn outlined color="#6D6D6D" depressed height="40px" v-bind="attrs" v-on="on">
      Important tips
      <v-icon class="ml-1">mdi-information-outline</v-icon>
    </v-btn>
  </template>

  <v-carousel :show-arrows="false"  color="black">
    <v-carousel-item>
      <v-card outlined class="ma-0" height="600px" color="white">
        <v-card-title class="black--text">How to copy data from Excel</v-card-title>
        <v-card-text class="black--text">
          Go to Excel and copy the data you wish to transfer. Go to SmartSize, select the first cell and paste the data with the following shortcut: Ctrl + V (Microsoft users), ⌘ + V (Mac users).
        </v-card-text>
        <v-img contain max-width="100%" max-height="100%" src="/copy_paste_data_excel.gif"></v-img>
      </v-card>
    </v-carousel-item>
    <v-carousel-item>
      <v-card outlined class="ma-0" height="600px" color="white">
        <v-card-title class="black--text">Automatic conversion: Exclude columns</v-card-title>
        <v-card-text class="black--text">
          Add ** inside the title. This will stop conversion in this column.
        </v-card-text>
        <v-img contain max-width="100%" max-height="100%" src="/exclude_conversion.gif"></v-img>
      </v-card>
    </v-carousel-item>
    <v-carousel-item>
      <v-card outlined class="ma-0"  height="600px" color="white">
        <v-card-title class="black--text" style="color: red">Automatic conversion: Incorrect values</v-card-title>
        <v-card-text class="black--text">
          Don't add 'cm' or " after the values if you wish to activate automatic conversion.
        </v-card-text>
        <v-img contain max-width="100%" max-height="100%" src="/values_incorrect.png"></v-img>
      </v-card>
    </v-carousel-item>
    <v-carousel-item>
      <v-card outlined class="ma-0" height="600px" color="white">
        <v-card-title class="black--text" style="color: green">Automatic conversion: Correct values</v-card-title>
        <v-card-text class="black--text">
          If you wish to activate automatic conversion, write values only (remove cm, no "). You can also write ranges. Example: 32 - 33
        </v-card-text>
        <v-img contain max-width="100%" max-height="100%" src="/values_correct.png"></v-img>
      </v-card>
    </v-carousel-item>
  </v-carousel>


</v-dialog>








    
    </v-row>

    <!-- Size table -->

    <div class="wrapper">
      <div class="items-container">
        <div class="item-cell"></div>
        <div
          v-for="(item, index) in rowHeader"
          v-bind:key="`rowHeader-${index}`"
          class="item-cell"
        >
          <input
            type="text"
            :name="`select-${index}`"
            :id="`select-${index}`"
            class="size-cell-input"
            v-model="item.chart_item_name"
          />
          <!-- <CustomSelect
            :defaultValue="item.chart_itemId"
            :name="`select-${index}`"
            @onChanged="(val) => selectMeasurement(val, index)"
          /> -->
          <v-btn depressed class="delete-button" @click="removeRow(index)">
            <v-icon color="white" size="18">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="item-cell">
          <v-btn depressed min-width="180" @click="addRow" >
             ADD <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="chart-container">
        <div class="sizes-row">
          <div
            v-for="(size, index) in colHeader"
            v-bind:key="`colHeader-${index}`"
            class="size-cell"
          >
            <input
              type="text"
              :value="size.size"
              class="size-cell-input"
              @change="changeSize($event, index)"
            />
            <v-btn depressed class="delete-button" @click="removeColumn(index)">
              <v-icon color="white" size="18">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <VueEditableGrid
          class="sized-chart-style"
          ref="grid"
          id="chartData"
          :enableFilters="false"
          :itemHeight="50"
          :column-defs="columnDefs"
          :row-data="rows"
          :pageCount="0"
          row-data-key="shipmentId"
          @cell-updated="cellUpdated"
          :editable="true"
        />
      </div>
      <div class="add-column-container">
        <div class="add-button">
          <v-btn depressed width="80"   @click="addColumn">
            ADD  <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>          </v-btn>
        </div>
      </div>
    </div>

    <!-- Footer -->

    <v-text-field
    class="mb-5"
      v-model="footer"
      label="Footer (optional)"
      clearable
      color="#F1B24A"
      @change="(e) => setFooter(e)"
      v-if="size_popup_version"
    ></v-text-field>

    <!-- Conversion-->
    <v-row>
      <v-col sm="12" md="6">
        <v-card min-height="160px" color="#EEEEEE">
      <v-card-title class="text-overline mb-1" style="font-weight: bold">  
              CONVERSION CM / INCHES
         </v-card-title>
      <v-row class="ma-4 d-flex justify-start">
       <v-select
       
          :items="unitItems.map((item) => item.measure)"
          :label="'Initial values'"
          v-model="unit.value"
          flat
          @change="changeUnits($event)"
        ></v-select>
        <v-checkbox
          color="#6D6D6D"
          :input-value="allow_metric_conversion"
          :label="'Conversion cm/inches'"
          @change="(e) => toggleAllowMetricConversion(this.sizeChartID, e)"
        ></v-checkbox>

      </v-row>
    </v-card>
      </v-col>
<v-col sm="12" md="6">
  <v-card
    min-height="160px"
    color="#EEEEEE"
    v-if="size_popup_version"
  >
  <v-card-title class="text-overline mb-1" style="font-weight: bold">  
              POPUP WIDTH (DESKTOP)
         </v-card-title>
      <v-row class="ma-4 d-flex align-center" >
      
        <v-radio-group
          v-model="popup_width_setting"
          row
          @change="(e) => selectPopupWidth(e)"
          class="pl-4"
        >
          <v-radio color="cyan" value="small" label="Small"></v-radio>
          <v-radio color="cyan" value="medium" label="Medium"></v-radio>
          <v-radio color="cyan" value="large" label="Large"></v-radio>
          <v-radio color="cyan" value="maximum" label="Maximum"></v-radio>
        </v-radio-group> </v-row
    ></v-card>

</v-col>
    </v-row>   

    <v-divider> </v-divider>

    <!-- Popup width-->

  
    </v-row>

    <v-divider class="mt-8"> </v-divider>


  </div>
</template>

<script>
import VueEditableGrid from "vue-editable-grid";
import "vue-editable-grid/dist/VueEditableGrid.css";
import CustomSelect from "../CustomSelect/CustomSelect.vue";

export default {
  components: { VueEditableGrid, CustomSelect },
  props: [
    "rowItems",
    "colItems",
    "measureData",
    "unitValue",
    "sizeChartID",
    "allow_metric_conversion",
    "popup_width_setting",
    "subtitle",
    "footer",
  ],
  data() {
    return {
      unit: this.unitValue,
      columnDefs: [],
      rows: [],
      selectedRow: null,
      rowHeader: this.rowItems,
      colHeader: this.colItems,
      chartData: this.measureData,
      dialog_tips: false,

      check: 0,
      popup_width: this.popup_width_setting,
      subtitle: this.subtitle,
      footer: this.footer,
    };
  },
  created() {
    this.initTable();
  },
  watch: {
    rowItems(newVal) {
      setTimeout(() => {
        this.rowHeader = newVal;
        this.initTable();
      }, 1000);
    },
    colItems(newVal) {
      setTimeout(() => {
        this.colHeader = newVal;
        this.initTable();
      }, 1000);
    },
    unitValue(newVal) {
      setTimeout(() => {
        this.unit = newVal;
        this.initTable();
      }, 1000);
    },
    measureData(newVal) {
      this.chartData = newVal;
      this.initTable();
    },
  },
  computed: {
    unitItems() {
      return this.$store.getters.getChartUnits;
    },
    size_popup_version() {
      return this.$store.getters.getShopProfile.size_popup_version;
    },
  },
  methods: {
    initTable() {
  this.columnDefs = [];
  this.rows = [];
  
  this.colHeader.forEach((item, cIndex) => {
    this.columnDefs.push({
      field: `size${cIndex}`,
      headerName: item,
      editable: true,
    });
  });
  
  let temp_row = [];
  
  this.rowHeader.forEach((_, rIndex) => {
    if (this.chartData[rIndex]) { // Check if this.chartData has an entry at rIndex
      this.colHeader.forEach((_, cIndex) => {
        if (this.chartData[rIndex][cIndex]) { // Check if this.chartData[rIndex] has an entry at cIndex
          console.log("this.chartData[rIndex][cIndex].measure", this.chartData[rIndex][cIndex].measure);
          temp_row[rIndex] = {
            ...temp_row[rIndex],
            [`size${cIndex}`]: this.chartData[rIndex][cIndex].measure,
          };
        } else {
          console.warn(`Missing chartData for row ${rIndex} and column ${cIndex}`);
          temp_row[rIndex] = {
            ...temp_row[rIndex],
            [`size${cIndex}`]: "", // Default value if data is missing
          };
        }
      });
    } else {
      console.warn(`Missing chartData for row ${rIndex}`);
    }
  });
  
  this.rows = temp_row;
  
  if (this.rows.length == 0 && this.check == 0) {
    this.check++;
    this.addColumn();
    this.addColumn();
    this.addColumn();
    this.addRow();
    this.addRow();
    this.addRow();
  }
}
,
    cellUpdated($event) {
      this.chartData[$event.rowIndex][$event.columnIndex] = {
        ...this.chartData[$event.rowIndex][$event.columnIndex],
        measure: $event.value,
      };
      this.initTable();
      this.$emit(
        "changeSizeChart",
        this.rowHeader,
        this.colHeader,
        this.chartData,
        this.unit
      );
    },
    addColumn() {
      this.colHeader.push({ id: 0, size: "" });
      this.chartData.forEach((item) => {
        item.push({ id: 0, measure: "" });
      });
      this.initTable();
      this.$emit(
        "changeSizeChart",
        this.rowHeader,
        this.colHeader,
        this.chartData,
        this.unit
      );
    },
    changeSize(event, index) {
      const value = event.target.value;
      this.colHeader[index].size = value;
      this.initTable();
      this.$emit(
        "changeSizeChart",
        this.rowHeader,
        this.colHeader,
        this.chartData,
        this.unit
      );
    },
    removeColumn(index) {
      if (this.colHeader[index].id !== 0)
        this.$emit("deleteColumn", this.colHeader[index].id);
      this.colHeader.splice(index, 1);
      this.chartData.forEach((_, rIndex) => {
        this.chartData[rIndex].splice(index, 1);
      });
      this.initTable();
      this.$emit(
        "changeSizeChart",
        this.rowHeader,
        this.colHeader,
        this.chartData,
        this.unit
      );
    },
    addRow() {
      this.rowHeader.push({ id: 0, chart_itemId: "" });
      this.chartData.push(
        Array(this.colHeader.length).fill({ id: 0, measure: "" })
      );
      this.initTable();
      this.$emit(
        "changeSizeChart",
        this.rowHeader,
        this.colHeader,
        this.chartData,
        this.unit
      );
    },
    selectMeasurement(value, index) {
      this.rowHeader[index].chart_itemId = value;
      this.initTable();
      this.$emit(
        "changeSizeChart",
        this.rowHeader,
        this.colHeader,
        this.chartData,
        this.unit
      );
    },
    removeRow(index) {
      if (this.rowHeader[index].id !== 0)
        this.$emit("deleteRow", this.rowHeader[index].id);
      this.rowHeader.splice(index, 1);
      this.chartData.splice(index, 1);
      this.initTable();
      this.$emit(
        "changeSizeChart",
        this.rowHeader,
        this.colHeader,
        this.chartData,
        this.unit
      );
    },
    changeUnits(value) {
      this.unit.value = value;
      this.initTable();
      this.$emit(
        "changeSizeChart",
        this.rowHeader,
        this.colHeader,
        this.chartData,
        this.unit
      );
    },
    toggleAllowMetricConversion(id, newVal) {
      this.$emit("toggleAllowMetricConversion", newVal);
    },
    selectPopupWidth(newVal) {
      this.$emit("selectPopupWidth", newVal);
    },
    setSubtitle(newVal) {
      this.$emit("setSubtitle", newVal);
    },
    setFooter(newVal) {
      this.$emit("setFooter", newVal);
    },
  },
};
</script>

<style lang="scss">
.sizechart-container {
  background-color: rgb(255, 255, 255);

  .wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0px;
    width: 100%;
    display: flex;
    background-color: white;
    height: 100%;
    margin-bottom: 10px;
    overflow-y: hidden;
    .items-container {
      width: 200px;
      min-width: 200px;
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 10;

      & > * + * {
        border-right: 1px solid #e9e9e9;
      }
      .item-cell {
        height: 50px;
        display: flex;
        justify-content: center;
        padding: 0 10px;
        align-items: center;
        &:last-child {
          border: none;
        }
      }
    }

    .chart-container {
      .sizes-row {
        height: 50px;
        width: 100%;
        display: flex;
        .size-cell {
          width: 150px;
          min-width: 150px;
          padding: 0 10px;
          display: flex;
          align-items: center;
          border-bottom: px solid #7c7878;
        }
      }
      .sized-chart-style {
        flex-grow: 1;
        height: 100%;

        .grid-tools {
          display: none;
        }
        .grid-table-container {
          height: 100%;
        }
        thead {
          display: none !important;
        }
        tbody {
          height: fit-content;
          overflow: auto;
          // border-left: 1px solid #e9e9e9;

          tr {
            display: flex;
            .cell {
              min-width: 150px;
              width: 150px;
            }
          }
        }
      }
    }

    .add-column-container {
      position: sticky;
      right: 0;
      z-index: 10;
      background-color: white;
      .add-button {
        height: 50px;
        display: flex;
        justify-content: center;
        padding: 0 10px;
        align-items: center;
      }
    }

    .size-cell-input {
      flex-grow: 1;
      width: 80%;
      height: 30px;
      margin-right: 2px;
      border-style: solid;

      border-radius: 5px;
      outline-color: #26c6da;

      text-align: center;
      &:focus-visible {
        outline: none;
      }
    }
    .delete-button {
      background-color: #000000 !important;
      width: 20px !important;
      min-width: 30px !important;
      height: 30px !important;
    }
  }

  .sizechart-footer {
    padding: 0px 00px 0px 20px;
    display: flex;
    align-items: center;
    justify-content: end;
    .units-container {
      padding-top: 20px;
      width: 160px;
    }

    .save-button {
      color: white;
    }
  }
}
</style>
