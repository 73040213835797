<template>
  <v-dialog
    v-model="dialog"
    scrollable
    content-class="preview-modal"
    :max-width="previewMode === 'cellphone' ? '400px' : '676px'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon class="close-button" v-bind="attrs" v-on="on">
        <v-icon color="black" size="20">mdi-{{ previewMode }}</v-icon>
      </v-btn>
    </template>
    <v-card color="#303030">
      <v-card-title class="d-flex justify-end pr-2" color="grey">
        <v-btn icon class="close-button" @click="dialog = false">
          <v-icon color="white" size="20">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title>
        <div class="text-overline mb-1" style="font-weight: bold; color: white">
          PREVIEW
        </div></v-card-title
      >
      <v-card-subtitle style="color: white"
        >This preview may differ from the real size chart. Please always check
        in your store
      </v-card-subtitle>

      <v-card-text>
        <iframe
          class="ifrm"
          id="ifrm"
          :src="getUrl()"
          style="width: 100%; border: none; height: 100%"
        ></iframe>
        <!-- <div
          v-if="editorType !== 'care'"
          class="popup-unit-select d-flex justify-end align-center"
        >
          <span>cm</span>
          <v-switch v-model="currentUnit" inset></v-switch>
          <span>in</span>
        </div>
        <div v-if="editorType !== 'care'" class="sizechart_wrapper">
          <table class="sizechart" cellspacing="0">
            <tr>
              <td></td>
              <td
                v-bind:key="`header${index}`"
                v-for="(item, index) in colHeader"
              >
                {{ item.size }}
              </td>
            </tr>
            <tr v-bind:key="`row${rIndex}`" v-for="(item, rIndex) in rowHeader">
              <td>
                {{ item.chart_item_name }}
              </td>
              <td
                v-bind:key="`cell-${rIndex}-${cIndex}`"
                :id="`cell-${rIndex}-${cIndex}`"
                v-for="(cell, cIndex) in chartData[rIndex]"
              >
                <span>
                  {{
                    unitValue == "Centimeters"
                      ? currentUnit
                        ? (parseFloat(cell.measure) * 0.393701).toFixed(1)
                        : cell.measure
                      : currentUnit
                      ? cell.measure
                      : (parseFloat(cell.measure) / 0.393701).toFixed(1)
                  }}
                </span>
              </td>
            </tr>
          </table>
        </div>

        <div class="preview-content">
          <div
            v-html="chartText"
            :class="`tinymce-content ${
              previewMode === 'cellphone' ? 'mobile-mode' : ''
            }`"
          ></div>
        </div> -->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    "chartText",
    "previewMode",
    "rowHeader",
    "colHeader",
    "chartData",
    "unitValue",
    "currentChart",
    "colorSettings",
    "allow_metric_conversion",
    "size_icon_text",
    "subtitle",
    "footer",
  ],
  data() {
    return {
      dialog: false,
      currentUnit: this.unitValue === "Inches",
      editorType: this.$route.query.type,
      reistrict: false,
    };
  },
  updated() {
    try {
      var iframes = document.getElementsByClassName("ifrm");
      for (var i = 0; i < iframes.length; i++) {
        iframes[i].contentWindow.location.reload();
      }
      /* console.log("update");
        let chart = {
          currentChart: {chartdata: this.currentChart,
          webg_user_chart_items: this.rowHeader,
          webg_user_chart_sizes: this.colHeader,
          webg_user_chart_measures: [{webg_chart_unitsId: (this.unitValue === "Inches")? 2:1}],
          webg_user_chart_measurements: this.chartData },
          test: true,
        }
        if(window.currentChart != chart){
          console.log("update the change");
          this.refresh();
        } */
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    measurementItems() {
      return this.$store.getters.getChartItems;
    },
  },
  methods: {
    /* refresh(){
      if(this.reistrict == false){
        this.reistrict = true;
        console.log("refresh");
        setTimeout(() => {this.reistrict = false;},1000);
        var iframes = document.getElementsByClassName('ifrm');
          for (var i = 0; i < iframes.length; i++) {
            iframes[i].contentWindow.location.reload();
          }

      }
    }, */
    getUrl() {
      console.log("geturl called");
      let chart = {
        currentChart: {
          size_icon_text: this.size_icon_text,
          chartdata: this.currentChart,
          color_schema: this.colorSettings,
          allow_metric_conversion: this.allow_metric_conversion,
          webg_user_chart_items: this.rowHeader,
          webg_user_chart_sizes: this.colHeader,
          webg_user_chart_measures: [
            { webg_chart_unitsId: this.unitValue === "Inches" ? 2 : 1 },
          ],
          webg_user_chart_measurements: this.chartData,
        },
        test: true,
        popup_setting_show_message: "",
      };
      window.currentChart = chart;
      return "/example.html?chartid=" + this.currentChart.id;
      if (window.currentChart != chart) {
        console.log(window.currentChart);
        console.log(chart);
        //localStorage.setItem("chartdata", JSON.stringify(chart));
      }
    },
  },
};
</script>

<style lang="scss">
@import "/sizefox.css";
.laptop {
  min-width: 60% !important;
}
.v-dialog.preview-modal {
  max-height: 90%;
  height: 100%;
  .close-button {
    background-color: transparent;
  }

  .popup-unit-select {
    .v-input--switch--inset {
      margin-left: 15px;
      .v-input--switch__track {
        color: black !important;
        opacity: 1;
      }

      .v-input--switch__thumb {
        color: white !important;
      }
    }
  }

  .v-card__text {
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 10px !important;

    .preview-content {
      overflow-x: visible;

      .tinymce-content {
        table {
          width: 100% !important;
        }
        table tbody tr {
          display: flex;
          flex-wrap: wrap;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &.mobile-mode {
          table tbody tr td {
            width: 100% !important;
          }
        }
      }
    }
  }
}
.tinymce-content p img {
  width: 100%;
  //max-width: max-content;
  height: auto;
  object-fit: scale-down;
}
</style>